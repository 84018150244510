import { Injectable } from '@angular/core';
import  {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable,BehaviorSubject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { User } from '../models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private httpClient: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('Backend returned code: '+error +' body was: '+error);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  // REGISTER
  registerV1(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this.httpClient.post<any>('https://apibackend.winner-system.com:3000/api/v1/users/register', data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  register(data: any): Observable<any> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();

    params = params.append('password',data.password);
    params = params.append('pseudo',data.pseudo);
    params = params.append('email',data.email);
    params = params.append('firstName',data.firstName);
    params = params.append('lastName',data.lastName);
    params = params.append('telephone',data.telephone);
    params = params.append('address',data.address);
    let url_v1="http://api.winner-system.com";
    let url_v2="https://apibackend.winner-system.com:3000";
    return this.httpClient.post<any>('https://apibackend.winner-system.com:3000/api/v1/user/register',params,{headers:headers})
      .pipe(map(result => {

        if (result && result.data) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(result.data));
            // this.currentUserSubject.next(result.data);
        }
        return result;
      })
    );
  }
  // VALIDE ACCOUNT
  valid(data: any): Observable<any> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();

    params = params.append('email',data.email);
    params = params.append('code',data.code);

    return this.httpClient.put<any>('https://apibackend.winner-system.com:3000/api/v1/user/valid',params,{headers:headers})
      .pipe(map(result => {

        if (result && result.data) {}
        return result;
      })
    );
  }
  // LOGIN
  login(data: any) : Observable<any> {

    let headers = new HttpHeaders();
    headers  = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();
    params = params.append('email',data.email);
    params = params.append('password',data.password);
// https://api.mamyerim.com/api/v2/auth/signin
    return this.httpClient.post<any>('https://api.mamyerim.com/api/v2/auth/signin',params.toString(), {headers:headers})
      .pipe(map(data => {
        if (data && data.result && data.result.accessToken) {
            localStorage.setItem('currentUser', JSON.stringify(data.result));
            this.currentUserSubject.next(data.result);
        }
        return data;
      })
    );
  }
  // LOGOUT
  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
  // RESET PASSWORD
  reset1(data: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
      params:data
    };

    return this.httpClient.get<any>('https://apibackend.winner-system.com:3000/api/v1/user/reset-password', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  // VALIDE ACCOUNT
  reset(data: any): Observable<any> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();

    params = params.append('email',data.email);

    return this.httpClient.post<any>('https://apibackend.winner-system.com:3000/api/v1/user/reset-password',params,{headers:headers})
      .pipe(map(result => {

        if (result && result.data) {}
        return result;
      })
    );
  }
  // CHANGE PASSWORD
  change1(data: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
    };

    return this.httpClient.put<any>('https://apibackend.winner-system.com:3000/api/v1/users/password/change',data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  change(data: any): Observable<any> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('', 'application/x-www-form-urlencoded;charset=utf-8');

    let params = new HttpParams();

    params = params.append('code',data.code);
    params = params.append('email',data.email);
    params = params.append('newPassword',data.newPassword);

    return this.httpClient.post<any>('https://apibackend.winner-system.com:3000/api/v1/user/change-password',params,{headers:headers})
      .pipe(map(result => {

        if (result && result.data) {}
        return result;
      })
    );
  }
  // EDIT PROFILE
  editProfile(data: any): Observable<any> {
    let user=this.currentUserValue;

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();

    params = params.append('newPassword',data.newPassword);
    params = params.append('oldPassword',data.olPassword);
    params = params.append('Authorization',user.token);

    params = params.append('pseudo',data.pseudo);
    params = params.append('email',data.email);
    params = params.append('firstName',data.firstName);
    params = params.append('lastName',data.lastName);
    params = params.append('telephone',data.telephone);
    params = params.append('address',data.address);

    return this.httpClient.put<any>('https://apibackend.winner-system.com:3000/api/v1/user/update',params,{headers:headers})
      .pipe(map(result => {

        if (result && result.data && result.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(result.data));
            this.currentUserSubject.next(result.data);
        }
        return result;
      })
    );
  }
  // LIST
  listV1(page:number,size:number): Observable<any> {


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    return this.httpClient.get<any>('https://apibackend.winner-system.com:3000/api/v1/users/management?pageNo='+page+'&size='+size)
      .pipe(map(result => {
        if (result && result.data) {
            //console.log('NewData: '+JSON.stringify(result));
        }
        return result;
      })
    );
  }
  list(page:number,size:number): Observable<any> {

    let user=this.currentUserValue;
    let headers = new HttpHeaders();
    headers  = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers  = headers.append('Authorization', user.token);

    return this.httpClient.get('https://apibackend.winner-system.com:3000/api/v1/users/'+page+'/'+size,{headers:headers})
      .pipe(map(result => {
        if (result) {
        }
        return result;
      })
    );

  }
  //ALL USER
  allUsers(): Observable<any> {

    let user=this.currentUserValue;
    let headers = new HttpHeaders();
    headers  = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers  = headers.append('Authorization', user.token);

    return this.httpClient.get('https://apibackend.winner-system.com:3000/api/v1/user/all',{headers:headers})
      .pipe(map(result => {
        if (result) {
        }
        return result;
      })
    );

  }
  // GET-PROFILE
  getProfile(): Observable<any> {

    let user=this.currentUserValue;
    let headers = new HttpHeaders();
    headers  = headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    //headers  = headers.append('Authorization', user.token);

    return this.httpClient.get('https://apibackend.winner-system.com:3000/api/v1/users/profile',{headers:headers})
      .pipe(map(result => {
        if (result) {
        }
        return result;
      })
    );

  }
  setAdminV1(id:string,active:boolean): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    const data:any= {
      "id":id,
      "admin":active
    };
    return this.httpClient.put<any>('https://apibackend.winner-system.com:3000/api/v1/users/admin',data,httpOptions)
      .pipe(map(result => {
        if (result) {
            //console.log('NewData: '+JSON.stringify(result));
        }
        return result;
      })
    );
  }
  setAdmin(id:string,active:string): Observable<any> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    headers.append('', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();

    params = params.append('id',id);
    params = params.append('admin',active);
    let user=this.currentUserValue;
    params = params.append('Authorization',user.token);

    return this.httpClient.put<any>('https://apibackend.winner-system.com:3000/api/v1/user/admin',params,{headers:headers})
      .pipe(map(result => {

        if (result) {}
        return result;
      })
    );
  }


}

