import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENU',
        isTitle: true
    },
    //DASHBOARD
    {
        id: 2,
        label: 'TABLEAU DE BORD',
        icon: 'bx-home-circle',
        badge: {
            variant: 'info',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/dashboard'

    },
    // DONNEES
    {
      id: 4,
      label: 'DONNEES',
      icon: 'bx-briefcase-alt-2',
      subItems: [
        {
          id: 7,
          label: 'CVs',
          link: '/cvs',
          parentId: 4
        },{
          id: 6,
          label: 'Clients',
          link: '/clients',
          parentId: 4
        },
        {
          id: 5,
          label: 'Projets',
          link: '/projects/list',
          parentId: 4
        },
        {
          id: 6,
          label: 'Démo',
          link: '/dashboard',
          parentId: 4
        },


        {
          id: 8,
          label: 'Demande d\'entretien',
          link: '/dashboard',
          parentId: 4
        },

      ]
    },
    // LEADS
    {
      id: 9,
      label: 'LEADS',
      icon: 'bx-wallet-alt',
      subItems: [
        {
          id: 10,
          label: 'Programmation',
          link: '/dashboard',
          parentId: 9
        },
        {
          id: 11,
          label: 'Design & Créativité',
          link: '/dashboard',
          parentId: 9
        },{
          id: 12,
          label: 'IT & Réseaux',
          link: '/dashboard',
          parentId: 9
        },{
          id: 13,
          label: 'Consultant IT',
          link: '/dashboard',
          parentId: 9
        },{
          id: 14,
          label: 'E-commerce',
          link: '/dashboard',
          parentId: 9
        },{
          id: 15,
          label: 'Gestions de projets',
          link: '/dashboard',
          parentId: 9
        }
      ]
    },
    //CONFIGURATIONS
    {
      id: 16,
      label: 'CONFIGURATIONS',
      icon: 'bx-wallet-alt',
      subItems: [
        {
          id: 17,
          label: 'Compétence Techniques',
          link: '/comptence-techniques',
          parentId: 16
        },
        {
          id: 18,
          label: 'Services',
          link: '/dashboard',
          parentId: 16
        },
        {
          id: 19,
          label: 'Diplômes',
          link: '/dashboard',
          parentId: 16
        }
      ]
    },
];

