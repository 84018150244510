<div class="container-fluid">

  <app-page-title title="Profil" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-xl-12">

      <!-- INFOS PERSONNELLE -->
      <div class="card">
        <div class="card-body">

          <h4 class="card-title mb-4">
            <div style="position:relative;width: 100%;">
              Information personnelle
              <a routerLink="/profile/edit" class="btn btn-primary" style="position: absolute; right: 0px;">
                <i class="mdi mdi-pencil"></i>
              </a>
            </div>
          </h4>

          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Pseudo :</th>
                  <td style="text-transform: uppercase;">{{ currentUser?.pseudo }}</td>
                </tr>
                <tr>
                  <th scope="row">Prénom :</th>
                  <td style="text-transform: uppercase;">{{ currentUser?.firstName }}</td>
                </tr>
                <tr>
                  <th scope="row">Nom :</th>
                  <td style="text-transform: uppercase;">{{ currentUser?.lastName }}</td>
                </tr>
                <tr>
                  <th scope="row">Mobile :</th>
                  <td style="text-transform: uppercase;">{{ currentUser?.telephone }}</td>
                </tr>
                <tr>
                  <th scope="row">E-mail :</th>
                  <td style="text-transform: uppercase;">{{ currentUser?.email }}</td>
                </tr>
                <tr>
                  <th scope="row">Adresse :</th>
                  <td style="text-transform: uppercase;">{{ currentUser?.address }}</td>
                </tr>
                <tr>
                  <th scope="row">Profil :</th>
                  <td *ngIf="currentUser?.admin== 1" style="text-transform: uppercase;">Admin</td>
                  <td *ngIf="currentUser?.admin== 0" style="text-transform: uppercase;">User</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

  </div>
  <!-- end row -->
</div>
