import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/core/services/user.service';
import { revenueBarChart, statData } from './data';
import { ChartType } from './profile.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

/**
 * Contacts-profile component
 */
export class ProfileComponent implements OnInit{
  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueBarChart: ChartType;
  statData;
  facebook="facebook";
  twitter="twitter";
  youtube="youtube";
  linkedin="linkedin";
  instagram="instagram";

  profile={
    'user':{
      'first_name': 'FASEYA DEV',
      'last_name': 'FASEYA DEV',
      'user_name': 'FASEYA DEV',
      'phone':'+221 77 460 56 66',
      'email':'developper@faseya.com',
      'adresse':'Dakar, SN',
      'pseudo':'FASEYA DEV',
      'social_links':[
        { 'url': 'facebook.com/faseya-dev', 'name':'facebook'},
        { 'url': 'twitter.com/faseya-dev', 'name':'twitter'},
        { 'url': 'youtube.com/faseya-dev', 'name':'youtube'},
        { 'url': 'linkedin.com/faseya-dev', 'name': 'linkedin' },
        { 'url': 'instagram.com/faseya-dev', 'name': 'instagram' }
      ]
    },
    'projects':3,
    'revenue':6500000,
    'projects_completed':2,
    'pending_projects':1,
    'total_revenue':2500000,
    'current':'F CFA'

  };

  currentUser:any;

  constructor(private userService:UserProfileService) {}

  ngOnInit() {
    //this.initialize();

    //this.currentUser=this.userService.currentUserValue;
    this.breadCrumbItems = [{ label: 'Contacts' }, { label: 'Profil', active: true }];
    this.getUserProfil();
    // fetches the data
    //this._fetchData();
  }
  /**
   * Fetches the data
   */
  private _fetchData() {
    this.revenueBarChart = revenueBarChart;
    this.statData = statData;
  }
  getUserProfil(){
    this.userService.getProfile()
        .subscribe(
          resultat => {
            if (resultat.status==='ok') {
              this.currentUser=resultat.data;
              // console.log('statistiqueData: ',this.statistiqueData);
            }else{
            }
          },
          error => {
          });
  }
}
