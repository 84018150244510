import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import { UserProfileService } from '../services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
      private authenticationService: AuthenticationService,
      private authfackservice: AuthfakeauthenticationService,
      private userService:UserProfileService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.defaultauth === 'firebase') {
            const currentUser = this.userService.currentUserValue;
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `${currentUser.token}`,

                    }
                });
            }
        } else {
            // add authorization header with jwt token if available
            const currentUser = this.userService.currentUserValue;
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                      //Authorization: `${currentUser.token}`
                      Authorization: `Bearer ${currentUser.token}`


                    }
                });
            }
        }
        return next.handle(request);
    }
}
